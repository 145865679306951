b-user-icon:focus,
b-user-icon:focus-within {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--blue-1);
  outline: none;
}

b-user-icon {
  position: relative;
  display: block;
  background-color:  var(--blue-2);
  border-radius: 3px;
}

b-user-icon > div {
  cursor: pointer;
  display: flex;
  padding: 4px 4px 4px 8px;
  place-items: center;
}

b-user-icon img {
  display: inline-block;
  margin-left: 5px;
  width: 29px;
  padding: 3px;
}
