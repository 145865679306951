.wordatar {
  display: inline-block;
  line-height: 0px;
  border-radius: 100%;
  padding: 10px;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  color: white;
}

.wordatar span {
  display:inline-block;
  width: 100%;
  padding-top:50%;
  padding-bottom:50%;
  text-align: center;
}