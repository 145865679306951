header {
  background-color: #3F445A;
  color: white;
  padding: 5px;
  display: flex;
  place-items: center;
}

header .logo {
  display: block;
  height: 40px;
  background-image: url(../images/logo.svg);
  background-repeat: no-repeat;
  background-size: 158px;
  background-position: center left 3px;

}

header > *:first-child {
  flex-grow: 1;
}

header > *:last-child {
  color: white;
}
