b-form-field {
  display: block;
  margin-bottom: 20px;
}

b-form-field label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
 
b-form-field input {
  font-size: 100%;
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}




b-form-checkbox {
  display: flex;
  margin-bottom: 20px;
}

b-form-checkbox > *:last-child {
  flex-grow: 1;
}

b-form-checkbox label {
  display: block;
  margin-bottom: 5px;
  margin-left: 10px;
}
 
b-form-checkbox input:checked {
  background-color: black;
  box-shadow: inset 0 0 1px 2px white;
}

b-form-checkbox input {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid black;
  cursor: pointer;
  appearance: none;
  width: 24px;
  height: 24px;
  font-size: 1px;
  padding: 10px;
  margin: 0;
}






b-form-radio {
  display: flex;
  margin-bottom: 5px;
}

b-form-radio > *:nth-child(2) {
  flex-grow: 1;
}

b-form-radio label {
  display: block;
  margin-bottom: 5px;
  margin-left: 10px;
}
 
b-form-radio input:checked {
  background-color: black;
  box-shadow: inset 0 0 1px 2px white;
}

b-form-radio input {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid black;
  border-radius: 200px;
  cursor: pointer;
  appearance: none;
  width: 24px;
  height: 24px;
  font-size: 1px;
  padding: 10px;
  margin: 0;
}






b-form-error {
  display: block;
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  background-color: #894141;
  color: white;
  display: block;
}

.form-page form {
  background-color: white;
  padding: 10px;
}

.form-page button {
  cursor: pointer;
  background-color: #3f445a;
  padding: 10px;
  border: 0;
  color: white;
  font-size: 100%;
  width: 100%;
}
