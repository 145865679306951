.badge {
  display: inline-block;
  padding: .25em .4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 20px;
}

.badge-red {
  color: #fff;
  background-color: #831b1b;
}