b-auth-box {
  background-color: white;
  display: block;
  overflow: hidden;
  max-width: 600px;
  margin: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

b-auth-box > *:first-child {
  background-color: #3F445A;
  padding: 18px;
  width: 100%;
  height: 80px;
}

b-auth-box > *:not(:first-child):not(:last-child) {
  padding: 10px;
}

b-auth-box > *:last-child {
  background-color: #e6e8f1;
  color: #3c3d46;
  margin-top: 20px;
  padding: 10px;
}

b-auth-box .button-primary {
  cursor: pointer;
  background-color: #3f445a;
  padding: 7px;
  border: 0;
  color: white;
  font-size: 100%;
  width: 100%;
}

b-auth-box img {
  height: 100px;
  text-align: center;
}
