footer {
  display: flex;
  background-color: #3F445A;
  color: white;
  padding: 0px;
  padding-bottom: env(safe-area-inset-bottom);
}

footer > * {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 12px;
}

footer > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

footer > a.active {
  background-color: var(--blue-1);
}

footer > a {
  flex: auto;
  cursor: pointer;
  width: 90px;
  padding: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

footer > a > img {
  width: 30px;
  margin-top: 5px;
  margin-bottom: 10px;
  filter: brightness(2);
}

footer > a > span {
  text-align: center;
}

footer > a > *:first-child {
  flex-grow: 1;
}

.lessThanViewportHeight footer {
  padding-bottom: 0;
}