.avatar img {
  display: inline-block;
  line-height: 0px;
  border-radius: 100%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  color: white;
}

.listview > * > *.avatar {
  padding: 0 0 0 10px;
}
