.chat-content {
  display: flex;
  flex-direction: column;
}

.chat-list {
  display: flex;
  flex-direction: column-reverse;
  padding: 5px 5px 20px 5px;
  background-image: url(../images/chat-background.png);
}

.chat-list > * > * {
  display: flex;
}

.chat-list > * > * > *:nth-child(1) {
  margin-bottom: 10px;
}

.chat-list > * > * > *:nth-child(2) {
  padding: 10px 7px;
  background-color: white;
  color: rgb(63 68 90);
  margin-bottom: 3px;
  max-width: 90%;
  margin: 5px;
  border-radius: 15px;
  box-shadow: 6px 6px 6px -6px #dbdbdb;
}

.chat-list strong {
  font-size: 75%;
  display: block;
}

.message-self > *:nth-child(2) {
  background-color: rgb(63 68 90);
  color: white;
  align-self: flex-end;
}

.message-sending {
  opacity: 0.5;
}

.chat-list > * > * > * {
  align-self: flex-start;
}

.chat-list .date-group {
  padding: 10px;
  color: var(--blue-1);
  margin-top: 50px;
  place-content: center;
  font-size: 80%;
  font-weight: bold;
}

.chat-list .message-self > .profile-picture {
  display: none;
}

.chat-list .message-self .message-time {
  text-align: right;
}
.chat-list .message-time {
  display: block;
  font-size: 70%;
  opacity: 0.6;
  margin-top: 7px;
}

.chat-footer {
  display: flex;
  padding: 5px;
}

.chat-footer textarea:active,
.chat-footer textarea:focus {
  outline: none;
}

.chat-footer .text-input {
  padding: 1px;
  margin: 5px 0 5px 5px;
  border: 0;
  border-radius: 3px;
  background-color: white;
  border: 1px solid var(--bumbana-blue);
  flex-grow: 1;
  max-height: 150px;
  overflow: auto;
  display: flex;
}   

.chat-footer textarea  {
  font-size: 100%;
  padding: 6px;
  width: 100%;
  border: 0;
  resize: none;
}

.chat-footer .buttons .send-button {
  background-color: var(--bumbana-blue);
  background-image: url(../images/send.svg);
}

.chat-footer .buttons button {
  border-radius: 200px;
  width: 32px;
  height: 32px;
  background-size: 50%;
  background-position: 40%;
  background-repeat: no-repeat;
  padding: 18px;
  border: 0;
  margin-right: 5px;
  cursor: pointer;
}

.chat-footer .buttons {
  padding-left: 10px;
  place-self: flex-end;
  flex: none;
  margin-bottom: 6px;
}

.message-self {
  flex-direction: row-reverse;
}

.profile-picture > img {
  width: 100%;
}
.profile-picture {
  border-radius: 200px;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  background-size: 100%;
  background-repeat: no-repeat;
  align-self: flex-end;
  overflow: hidden;
}

.chat-content pre {
  margin: 0;
  white-space: pre-wrap;
  overflow-x: auto;
}
