@import "./b-auth-box.css";
@import "./b-user-icon.css";
@import "./b-user-menu.css";
@import "./b-dropdown.css";
@import "./wordatar.css";
@import "./avatar.css";
@import "./listview.css";
@import "./chat-list.css";
@import "./header.css";
@import "./footer.css";
@import "./forms.css";
@import "./badges.css";

* {
  box-sizing: border-box;
  font-family: arial;
  -webkit-tap-highlight-color: transparent;
}

body {
  --bumbana-red: #982c2d;
  --bumbana-blue: #3f445b;
  --blue-1: #282c41;
  --blue-2: #4d5164;

  overscroll-behavior-y: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
  background-color: rgb(240, 240, 240);
}

p {
  margin: 0;
  margin-bottom: 20px;
}

.heading {
  background-color: #3b3b3b;
  color: white;
  border-bottom: 2px solid #3f445a;
  display: flex;
  flex-wrap: nowrap;
  place-items: center;
  padding: 0 4px 0 4px;
}

.heading > * {
  white-space: nowrap;
}

.heading > .title {
  flex-grow: 1;
  font-weight: bold;
  padding: 5px;
}

.heading > .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
}

.heading > b-dropdown:focus-within > b-dropdown-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.heading > b-dropdown > b-dropdown-button {
  border-radius: 5px;
  width: 30px;
}

.heading > button,
.heading > a {
  font-weight: bold;
  cursor: pointer;
  background-color: white;
  color: var(--bumbana-blue);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  display: flex;
  place-content: center;
  min-height: 31px;
}

.heading > button > img,
.heading > a > img {
  height: 20px;
}

.not-found-page {
  padding: 10px;
  text-align: center;
}
.not-found-page > p {
  margin-bottom: 20px;
}
.not-found-page > button {
  background-color: white;
  font-size: 100%;
  padding: 6px;
}

.loading {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  padding: 10px;
  text-align: center;
}

.loading > p {
  margin-bottom: 20px;
}

.loading > img {
  width: 100px;
}

button:disabled {
  opacity: 0.3;
}

main {
  flex-grow: 1;
  overflow: auto;
}

.wrapper {
  height: 100vh;
  overflow: auto;
}

.wrapper:not(.with-padding) {
  display: flex;
  flex-direction: column;
}

.wrapper:not(.with-padding) > main {
  flex-grow: 1;
}

.wrapper.for-loading {
  color: white;
  background-color: var(--blue-2);
}

.wrapper.with-padding {
  padding: 10px;
  background-color: var(--blue-2);
}

.wrapper > div {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
}

.wrapper > div > div {
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: rgb(240, 240, 240);
}

.first-load * {
  animation-duration: 0ms !important;
}

.wrapper.page-transition > div > div {
  animation: slide-in 0.15s;
  animation-timing-function: ease-out;
}
.wrapper.page-transition > div.exit > div {
  animation: slide-out 0.15s;
  animation-timing-function: ease-in;
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-30px);
    opacity: 0.3;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(30vw);
    opacity: 0.3;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
